import.meta.env = {"BASE_ASSETS": "/", "BASE_SERVER": "/", "BASE_URL": "/", "DEV": true, "MODE": "development", "PROD": false, "SSR": false};import { assertClientRouting } from "/node_modules/vike/dist/esm/utils/assertRoutingType.js?v=ad0bbd36";
assertClientRouting();
import { initClientRouter } from "/node_modules/vike/dist/esm/client/client-routing-runtime/initClientRouter.js?v=ad0bbd36";
import { assertSingleInstance_onClientEntryClientRouting } from "/node_modules/vike/dist/esm/client/client-routing-runtime/utils.js?v=ad0bbd36";
import { removeFoucBuster } from "/node_modules/vike/dist/esm/client/shared/removeFoucBuster.js?v=ad0bbd36";
// @ts-ignore Since dist/cjs/client/ is never used, we can ignore this error.
const isProd = import.meta.env.PROD;
assertSingleInstance_onClientEntryClientRouting(isProd);
initClientRouter();
if (import.meta.env.DEV)
    removeFoucBuster();
